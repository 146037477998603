import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {NgIf, TitleCasePipe} from '@angular/common';

@Component({
  selector: 'app-name-cell-renderer',
  templateUrl: './name-cell-renderer.component.html',
  styleUrls: ['./name-cell-renderer.component.scss'],
  standalone: true,
  imports: [NgIf, TitleCasePipe]
})
export class NameCellRendererComponent implements OnInit, ICellRendererAngularComp {
  private static readonly defaultImgPath = '../../../assets/images/default-profil-circle.svg';
  public lastName: string;
  public firstName: string;
  public imgPath: string;

  ngOnInit() {
    this.imgPath = NameCellRendererComponent.defaultImgPath;
  }

  agInit(params): void {
    this.firstName = params.data.firstName;
    this.lastName = params.data.lastName;
  }

  refresh(): boolean {
    return false;
  }
}
